@import "./variables.scss";
.panier-content {
  display: flex;
  align-items: flex-start;
  .grow {
    flex-grow: 1;
  }
}
.mod-panier {
  .produit-key {
    color:$mainColor;
  }
  .link {
    color:$mainColor;
    cursor: pointer;
    font-weight:bold;
  }
}
.paniers {
  .produit-key {
    color:$mainColor;
  }
}
.panier-types {
  padding-right:15px;
}
.panier {
  cursor: pointer;
}
