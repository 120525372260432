@import "./variables.scss";
.produits {
  .produit-card {
    min-width: 275px;
    cursor:pointer;
    .MuiChip-root {
      position:absolute;
      top:10px;
      right:10px;
      background-color: #FFF;
      .stock {
        color:#000;
      }
    }
  }
  .MuiTypography-h3 {
    line-height:1;
    height:3em;
    overflow:hidden;
  }
  .MuiTypography-h4 {
    line-height:1.2;
    height:1.2em;
    overflow:hidden;
  }
  .MuiCard-root {
    min-width: 100%;
  }
  .familles-chooser {
    .MuiChip-root {
      margin: 0 5px 5px 0;
      &:hover {
        cursor: pointer;
      }
      &.MuiChip-filled {
        border:1px solid #0000;
      }
    }
  }
  .color-spot {
    position:absolute;
    top:10px;
    left:10px;
    width:24px;
    height:24px;
    border-radius: 4px;
    box-shadow: 2px 2px 5px #0002;
    pointer-events: none;
  }
}
.MuiGrid-root.produit-desc {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  .ref {
    cursor:pointer;
  }
  .produit-key {
    color:$mainColor;
  }
}
.MuiDialogTitle-root.produit-head {
  display:flex;
  .produit-titre {
    flex-grow: 1;
  }
  .stock {
    color:#000;
    font-size:1.2em;
    font-weight:800;
  }
}
.produit-stock-container {
  display:flex;
  justify-content: center;
  .stock {
    color:#000;
    font-size:1.2em;
    font-weight:800;
  }
}
.produit-xs {
  width:50%;
  margin-left:25%;
  position:relative;
}
.ean {
  font-family: 'libre_barcode_39_textregular';
  width:100%;
  padding-bottom:20%;
  position:relative;
  .ean-inner {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display:flex;
    text-align:center;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.produits-toggle {
  margin-top:10px;
}
