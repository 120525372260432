@import "./variables.scss";
.mvts-sm {
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.mvts-btns {
  button {
    margin-right:10px;
    margin-bottom:10px;
  }
}
.canvas {
  position:relative;
  width:100%;
  padding-bottom:80%;
  .canvas-inner {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    pointer-events:none;
    button {
      pointer-events:all;
    }
    .fleche {
      position:absolute;
      width:200px;
      height:200px;
      top:calc(50% - 100px);
      background-image: url(../../assets/fleche.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .chantiers {
      position:absolute;
      width:200px;
      height:200px;
      bottom:1em;
      right:0;
      background-image: url(../../assets/chantier.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .depot {
      position:absolute;
      width:200px;
      height:200px;
      bottom:1em;
      left:0;
      background-image: url(../../assets/depot.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .fournisseurs {
      position:absolute;
      width:200px;
      height:200px;
      top:0;
      left:calc(50% - 100px);
      background-image: url(../../assets/fournisseur.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .chantiers-label {
      position:absolute;
      width:200px;
      height:200px;
      bottom:0;
      right:0;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
    .depot-label {
      position:absolute;
      width:200px;
      height:200px;
      bottom:0;
      left:0;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
    .fournisseurs-label {
      position:absolute;
      width:200px;
      height:200px;
      top:1em;
      left:calc(50% - 100px);
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
    .RETOUR-FOURNISSEUR {
      position:absolute;
      width:300px;
      height:100px;
      top:calc(50% + 30px - 50px);
      left:calc(30% - 150px);
      display: flex;
      justify-content: center;
      align-items: center;
      .fleche {
        right:0;
        transform:rotate(90deg) scaleX(-1);
      }
      .MuiButton-root {
        background-color:red;
      }
    }
    .ENTREE-FOURNISSEUR {
      position:absolute;
      width:300px;
      height:100px;
      top:calc(50% - 30px - 50px);
      left:10%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .fleche {
        left:0;
        transform: rotate(270deg) scaleX(-1);
      }
      .MuiButton-root {
        background-color:green;
      }
    }
    .SORTIE-CHANTIER {
      position:absolute;
      width:300px;
      height:100px;
      top:calc(50% + 30px - 50px);
      right:calc(30% - 150px);
      display: flex;
      justify-content: center;
      align-items: center;
      .fleche {
        left:0;
        transform:rotate(270deg);
      }
      .MuiButton-root {
        background-color:red;
      }
    }
    .ENTREE-CHANTIER {
      position:absolute;
      width:300px;
      height:100px;
      top:calc(50% - 30px - 50px);
      right:10%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .fleche {
        right:0;
        transform:rotate(90deg);
      }
      .MuiButton-root {
        background-color:green;
      }
    }
    .ENTREE-STOCK {
      position:absolute;
      width:300px;
      height:100px;
      top:calc(90% - 30px - 50px);
      right:calc(50% - 150px);
      display: flex;
      justify-content: center;
      align-items: center;
      .fleche {
        left:calc(50% - 100px);
        top:calc(50% - 30px - 100px);
        transform: scaleX(-1) rotate(45deg);
      }
      .MuiButton-root {
        background-color:red;
      }
    }
    .RETOUR-STOCK {
      position:absolute;
      width:300px;
      height:100px;
      top:calc(90% + 30px - 50px);
      right:calc(50% - 150px);
      display: flex;
      justify-content: center;
      align-items: center;
      .fleche {
        top:calc(50% + 30px - 100px);
        left:calc(50% - 100px);
        transform: scaleX(-1) rotate(225deg);
      }
      .MuiButton-root {
        background-color:green;
      }
    }
  }
}
.image-grid.MuiGrid-root {
  display:flex;
  position:relative;
  flex-direction: column;
  justify-content: center;
  &>div {
    width:100%;
    &.mvt-produit-commentaire {
      height:2em;
    }
    .MuiChip-root {
      position:absolute;
      top:10px;
      right:10px;
      background-color: #FFF;
      .stock {
        color:#000;
      }
    }
  }
}
.mod-mouvement {
  .produit-key {
    color:$mainColor;
  }
}
.flag-key {
  color:$mainColor;
}
.chantier-lignes-header {
  background-color:$mainColor;
  .MuiTableCell-root {
    color:#FFF;
  }
}
.historique-mvt-ENTREE-FOURNISSEUR {
  background-color: var(--historique-mvt-color-ENTREE-FOURNISSEUR);
}
.historique-mvt-RETOUR-FOURNISSEUR {
  background-color: var(--historique-mvt-color-RETOUR-FOURNISSEUR);
}
.historique-mvt-ENTREE-STOCK {
  background-color: var(--historique-mvt-color-ENTREE-STOCK);
}
.historique-mvt-RETOUR-STOCK {
  background-color: var(--historique-mvt-color-RETOUR-STOCK);
}
.historique-mvt-ENTREE-CHANTIER {
  background-color: var(--historique-mvt-color-ENTREE-CHANTIER);
}
.historique-mvt-SORTIE-CHANTIER {
  background-color: var(--historique-mvt-color-SORTIE-CHANTIER);
}
.historique-mvt-MVT-INVENTAIRE {
  background-color: var(--historique-mvt-color-MVT-INVENTAIRE);
}
.MuiDataGrid-row {
  user-select:none;
}
.MuiDataGrid-cell[tabindex="0"] {
  user-select:text;
}
.MuiDataGrid-row.historique-mvt-even {
  background-color: $mainColorLight1;
  &.revert, &.reverted {
    background-color: $highlight2;
  }
}
.MuiDataGrid-root .MuiDataGrid-row:hover.historique-mvt-even, .MuiDataGrid-root .MuiDataGrid-row.Mui-selected.historique-mvt-even, .MuiDataGrid-root .MuiDataGrid-row:hover.Mui-selected.historique-mvt-even {
  background-color: $mainColorLight1;
  box-shadow: inset 0 0 50px #0002;
  &.revert, &.reverted {
    background-color: $highlight2;
  }
}
.MuiDataGrid-row.historique-mvt-odd {
  background-color: $mainColorLight2;
  &.revert, &.reverted {
    background-color: $highlight2;
  }
}
.MuiDataGrid-root .MuiDataGrid-row:hover.historique-mvt-odd, .MuiDataGrid-root .MuiDataGrid-row.Mui-selected.historique-mvt-odd, .MuiDataGrid-root .MuiDataGrid-row:hover.Mui-selected.historique-mvt-odd {
  background-color: $mainColorLight2;
  box-shadow: inset 0 0 50px #0002;
  &.revert, &.reverted {
    background-color: $highlight2;
  }
}
