@import "./variables.scss";
.messages {
  width:100%;
  background:$mainColorLight1;
  padding:10px;
  box-sizing: border-box;
  border-radius: 4px;
  .message {
    margin:2px 0;
    padding:2px 5px;
    background: $mainColorLight1;
    border-radius: 4px;
    position:relative;
    width:90%;
    left:0%;
    white-space: pre-line;
    overflow-wrap: break-word;
    &.me {
      background: $mainColorLight1;
      position:relative;
      width:90%;
      left:calc(10% - 10px);
      cursor: pointer;
    }
    .from {
      font-size:12px;
      opacity:0.5;
    }
    margin-top: 10px;
    &.same {
      margin-top: 2px;
      .from {
        display: none;
      }
    }
    .lu {
      position:absolute;
      bottom:-2px;
      right:2px;
    }
  }
}
.send-form {
  position: relative;
  .send-btn {
    position: absolute;
    top:5px;
    right:5px;
  }
}
