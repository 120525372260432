@import "./variables.scss";
.panier, .mod-panier {
  .panier-lignes-header {
    background-color:$mainColor;
    .MuiTableCell-root {
      color:#FFF;
    }
  }
  .actions > button {
    margin-right: 8px;
    margin-bottom: 8px;
  }
}
