.image-container {
  width:100%;
  position:relative;
  img {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    object-fit: contain;
    object-position: center;
    opacity:0;
    transition:opacity 0.2s;
  }
  &.loaded img{
    opacity:1;
  }
  .image-placeholder {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background-color: #eee;
  }
}
